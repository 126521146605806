$icon-font-path: '../fonts/';

@import "variables";

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "bower_components/font-awesome/scss/font-awesome.scss";
// endbower


//
// #Custom from SEAX... :/
// --------------------------------------------------

.ajaxOverlay {
    position: absolute;
    top:  0;
    left: 0;
    z-index: 1;
    @include square(100%);
    font-weight: bold;
    background-color: #fff;
}

span.clsH1ghL1ghtW0rd {
    font-weight: bold;
    text-transform: uppercase;
    background-color: #ffd;
}

.centeredBox {
    width: 80%;
    margin: 1em auto 40px;
    text-align: center;
}

.fa {
    // margin-top: 10px; // really? lol
}


//
// #Scaffolding
// --------------------------------------------------

a {
    text-decoration: underline;
}

hr {
    margin-top:    ($line-height-computed / 2);
    margin-bottom: ($line-height-computed / 2);

    @media (min-width: $screen-sm-min) {
        margin-top:    ($line-height-computed * 2);
        margin-bottom: ($line-height-computed * 2);
    }
}


//
// #Typography
// --------------------------------------------------

h1, .h1,
h2, .h2,
h3, .h3 {
    margin-bottom: $line-height-computed;
    font-family: $font-family-signika;

    small,
    .small {
      font-size: 75%;
    }
}

h2, .h2,
h3, .h3 {
    // text-transform: uppercase;
}

h2, .h2 {
    color: $brand-primary;
}

p {
  margin-bottom: 30px;
}

dl {
    margin-bottom: 0;
}

dt {
    @extend .h2;
    margin: 0;
}

.dl-horizontal {
    dd {
        margin-bottom: 5px;
    }

    @media (min-width: $dl-horizontal-breakpoint) {
        dt {
            text-align: left;
        }
        dd {
            margin-bottom: 0;
            line-height: (($font-size-h2 * $line-height-base) + (($font-size-h2 - $font-size-base) / 2));
        }
    }
}


//
// #Grid system
// --------------------------------------------------

.container-fluid {
    max-width: 1280px;
}


//
// #Tables
// --------------------------------------------------

.table {
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                padding-right: ($table-cell-padding * 2);
                padding-left:  ($table-cell-padding * 2);
                border-top: 0;
            }
        }
    }

    > thead > tr > th {
        @extend .h2;
        padding-top:    (($table-cell-padding / 2) + $line-height-computed);
        padding-bottom: (($table-cell-padding / 2) + $line-height-computed);
        background-color: $gray-lighter;
        border-bottom: 0;

        h2, .h2 {
            color: $text-color;
        }
    }

    > tbody > tr > td {
        padding-top:    ($table-cell-padding + 16px);
        padding-bottom: ($table-cell-padding + 16px);
    }
}

.table-responsive {
    @media screen and (max-width: $screen-xs-max) {
        -ms-overflow-style: scrollbar;
    }
}

@-moz-document url-prefix() {
    fieldset { display: table-cell; }
}


//
// #Buttons
// --------------------------------------------------

.placeholder {
    color: $input-color-placeholder;
}


//
// #Buttons
// --------------------------------------------------

.btn {
    font-family: $font-family-signika;
    text-decoration: none;
    text-transform: uppercase;
}

//
// #Navbars
// --------------------------------------------------

.navbar-brand {
    padding-top:    (($navbar-height - 65px) / 2);
    padding-bottom: (($navbar-height - 65px) / 2);
}

.navbar-default {
    .navbar-nav {
        > li > a {
            text-decoration: none;
            text-transform: uppercase;
            font-weight: bold;
        }

        > li {
            > .navbar-subscribe {
                color: #fff;
                background-color: $brand-primary;

                &:hover,
                &:focus {
                    color: #fff;
                    background-color: darken($brand-primary, 6.5%);
                }
            }

            > .navbar-login {
                color: $gray-dark;
                background-color: $brand-warning;

                &:hover,
                &:focus {
                    color: $gray-dark;
                    background-color: darken($brand-warning, 6.5%);
                }
            }

        }

        > .active > a {
            &:after {
                content: "";
                display: block;
                @include size(100%, 5px);
                margin-top:     5px;
                margin-bottom: -10px;
                background-color: $text-color;
            }
        }

        @media (min-width: $grid-float-breakpoint) {
          > li {
            > a {
                margin-top:    ((($navbar-height - $line-height-computed) / 2) - ($navbar-padding-horizontal / 2));
                margin-bottom: ((($navbar-height - $line-height-computed) / 2) - ($navbar-padding-horizontal / 2));
                padding-right: 8px;
                padding-left:  8px;
            }

            > .navbar-subscribe,
            > .navbar-login {
                margin-left: 5px;
            }
          }
        }

        @media (min-width: $screen-lg-min) {
            > li {
                > a {
                    padding-right: 15px;
                    padding-left:  15px;
                }
            }
        }
    }
}


//
// #Jumbotron
// --------------------------------------------------

.jumbotron {
    padding-bottom: ($jumbotron-padding * 2);
    margin-bottom: 0;

    a {
        &,
        &:hover,
        &:focus {
            color: $text-color;
        }
    }

    h1 + h2,
    .h1 + .h2 {
        margin-top:    (-$line-height-computed);
        margin-bottom: $line-height-computed;
        font-size: ($font-size-h2 * 1.5);
        color: $text-color;
        text-transform: none;
    }

    p {
        margin-bottom: $jumbotron-padding;
    }

    @media screen and (min-width: $screen-sm-min) {
        padding-bottom: ($jumbotron-padding * 3.2);
    }
}


//
// #Media
// --------------------------------------------------

.media-right,
.media > .pull-right {
    padding-left: 20px;
}

.media-left,
.media > .pull-left {
    padding-right: 20px;
}


//
// #Structure
// --------------------------------------------------

body {
    // padding-top: $navbar-height;
}

.space {
    margin-bottom: ($line-height-computed * 2);
}


//
// #Banner
// --------------------------------------------------

.banner {
    position: relative;
    overflow: hidden;
    margin-top: 30px;
}

    .banner-image {
        @extend .img-responsive;
    }

    .banner-text {
        width: 100%;
        padding: 6px 33px 0;
        color: #fff;
        text-decoration: none;
        background-color: $gray-dark;
        @include clearfix;

        h3 {
            text-decoration: underline;
        }

        @media (min-width: $screen-md-min) {
            position: absolute;
            right:  0;
            bottom: 0;
            width: 31.33%;
        }
    }


//
// #Content
// --------------------------------------------------

.content {
    padding-top:    15px;
    padding-bottom: 15px;

    @media (min-width: $screen-sm-min) {
        padding-top:    30px;
        padding-bottom: 30px;
    }
}


//
// #Spotlights
// --------------------------------------------------

.spotlight {
    &,
    &:hover,
    &:focus {
        color: $text-color;
        text-decoration: none;
    }
}

    .spotlight-title {
        margin-bottom: ($line-height-computed / 2);
        text-decoration: underline;
    }


//
// #Filters
// --------------------------------------------------

.filters {
    padding-top:    15px;
    padding-bottom: 15px;
    background-color: $brand-warning;

    h2, .h2,
    h3, .h3 {
        color: $text-color;
    }

    h2 + h3,
    .h2 + .h3 {
        margin-top: (-$line-height-computed / 2);
    }

    a {
        &,
        &:hover,
        &:focus {
            color: $text-color;
        }
    }
}

    .filters-dropdowns {
        margin-top: $line-height-computed;
    }

    .filters-prev,
    .filters-next {
        @extend .h3;
        margin: 0;
        text-transform: uppercase;

        .fa {
            color: $text-color;
            vertical-align: sub;
        }
    }

    .filters-prev .fa {
        margin-right: 10px;
    }

    .filters-next .fa {
        margin-left: 10px;
    }


//
// #Listing
// --------------------------------------------------

.table-nav {
    margin-bottom: ($line-height-computed);
    padding-top:    15px;
    padding-bottom: 15px;
    background-color: $gray-lighter;
}

    .table-prev {
        @extend .filters-prev;
        margin-bottom: $form-group-margin-bottom;

        @media (min-width: $screen-sm-min) {
            margin-bottom: 0;
        }
    }

    .table-next {
        @extend .filters-next;
    }

    .table-prev,
    .table-next {
        &,
        &:hover,
        &:focus {
            color: $text-color;
        }
    }

//
// #Heading
// --------------------------------------------------

.heading {
    @media (min-width: $screen-sm-min) {
        margin-top: 0;
        margin-bottom: 5px;
    }
}


//
// #Highlight
// --------------------------------------------------

.highlight {
    padding: .2em;
    background-color: rgba($brand-warning,.5);
}

//
// #Downloads
// --------------------------------------------------

.downloads {}

    .download {
        color: $text-color;

        &:hover,
        &:focus {
            color: $text-color;

            .fa {
                color: $link-hover-color;
            }
        }

        .fa {
            margin-right: 10px;
        }
    }


//
// #Footer
// --------------------------------------------------

.footer {
    padding-top:    30px;
    padding-bottom: 17px;
    color: #fff;
    background-color: $gray-dark;

    a {
        color: #fff;
    }
}

    .social-icons {
        margin: 12px 0 0;
        @include clearfix;

        > li {
            float: left;
            padding: 0;

            > a {
                position: relative;
                display: block;
                @include square(40px);
                font-size: 20px;
                line-height: 40px;
                text-align: center;
            }


            > .google   {
                background-color: #dd4c39;

                &:hover {
                    background-color: darken(#dd4c39, 6.5%);
                }
            }

            > .twitter  {
                background-color: #00acee;

                &:hover {
                    background-color: darken(#00acee, 6.5%);
                }
            }

            > .youtube  {
                background-color: #bb0102;

                &:hover {
                    background-color: darken(#bb0102, 6.5%);
                }
            }

            > .facebook {
                background-color: #3b5999;

                &:hover {
                    background-color: darken(#3b5999, 6.5%);
                }
            }
        }
    }

    .footer-logo {
        margin-top: 2px;
        margin-left: 50px;

        > img {
            display: block;
        }
    }


//
// #Browser
// --------------------------------------------------

@-webkit-viewport { width: device-width; }
@-moz-viewport    { width: device-width; }
@-ms-viewport     { width: device-width; }
@-o-viewport      { width: device-width; }
@viewport         { width: device-width; }


//
// #Accessibility
// --------------------------------------------------

#content:focus {
    outline: none;
}


//
// #More custom stuff from SEAX...
// --------------------------------------------------

.form-controlSmall {
    @extend .form-control; // hehe
    height: 26px;
    padding: 1px;
    color: $gray-base;
}

.clsHitList_SEAX {
    margin-bottom: 22px;
    margin-top:    22px;
    padding-right: 16px;
    padding-left:  16px;
    font-family: $font-family-signika;
    font-size:   24px;
    font-weight: bold;
    line-height: 1.428571429;
    color: #333;
    text-align: left;
    background-color: #eee !important;
}

.clsHitList_SEAXDocRef {
    padding-right: 16px;
    padding-left:  16px;
    font-family: $font-family-signika;
    font-size:   22px;
    font-weight: bold;
    line-height: 1.428571429;
    color: #333;
    text-align:right;
    background-color: #eee !important;
}

.form-group-Seax {
    margin-bottom: 10px;
}

.helpMessage {
    width: 80%;
    margin: 1em auto;
    padding: 0.5em;
    text-align: center;
    border: 5px solid #e5e5e5;
}

.quickHelpTitle {
    font-size:   1em;
    font-weight: bold;
}

.NewtoolbarBackground {
    height: 30px;
    font-weight: bold;
    font-size:   0.8em;
    background: url(../Images/gradient_new.jpg) 0% 0% repeat-x;
}

.NewtoolbarDate {
    color: #000;
    padding-left:  18px;
    padding-right: 35px;
    margin-top: 20px;
}

.Newtoolbar {
    color: #006699;
    padding: 2px 0px;
    margin-left:  8px;
    margin-right: 8px;
    margin-top:   2px;
    text-decoration: none;
    white-space: nowrap;
    background-color: #eee !important;
}

a.Newtoolbar {
    &:hover,
    &:active {
        text-decoration: underline;
    }
}

.NewtoolbarMenu {
    float: left;
    padding-top: 8px;
    width: 70%;
}

.NewtoolbarInput {
    height: 15px;
    margin-top: 3px;
    font-size: 1.1em;
}
